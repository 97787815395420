<template>
  <div id="app">
    <div id="nav-bar" 
      class="header--site" 
      :class="[ !me ? 'justify-end' : 'justify-between', environment ]"
    >
      <div class="flex items-center" id="cms-logo">
        <img src="/images/acoren-logo-header.png" /> <span class="pl-1">{{ environment.toUpperCase() }}</span>
      </div>
      <div v-if="me && viewSite" class="site-switcher flex items-center">
        <p class="mb-0 mr-4">Switch Site:</p>
        <site-select @update:value="switchSite" :useParams="true" />
        <site-create
          classes="btn btn-go btn-sm ml-2"
          text="Create Site"
          @site-created="updateSiteList"
          v-if="editSite"
        />
      </div>

      <div class="flex items-center">
        <nav aria-label="Account" class="account">
          <router-link v-if="!me" to="/login">Login</router-link>
          <router-link class="ml-5" v-if="$can('AdminLand')" to="/admin">Admin Land</router-link>
        </nav>
        <a v-if="me" href="#" @click.prevent="logout" class="ml-4">Logout</a>
      </div>
    </div>
    <!-- /#nav-bar.header--site -->

    <portal-target name="sectionHeader"></portal-target>

    <router-view id="main" class="hasSectionHeader" />

    <portal-target name="modal"></portal-target>
  </div>
</template>

<script>
import ME_QUERY from '@/graphql/queries/Me.gql'
import USER_QUERY from '@/graphql/queries/GetUserByEmail.gql'
import { SITE, SITE_LIST } from '@/graphql/sites.js'
import  SiteSelect  from '@/components/utils/SiteSelect'
import  SiteCreate  from '@/components/actions/SiteCreate'

// eslint-disable-next-line
import { onLogout } from '@/vue-apollo.js'

export default {
  name: 'App',
  data() {
    return {
      sites: [],
      site: null,
      me: null,
      userByEmail: null,
      environment: '',
      viewSite: false,
      editSite: false,
    }
  },
  components: {SiteSelect, SiteCreate},
  watch: {
    site: {
      handler(newVal) {
        if (this.$route.params.siteID) {
          this.$globals.site = newVal
        } else {
          this.$globals.site = {}
        }
      },
    },
  },
  mounted() {
    this.environment = process.env.VUE_APP_ENVIRONMENT ?? 'Production';
  },
  apollo: {
    site: {
      query: SITE,
      skip() {
        return !this.$route.params.siteID
      },
      variables() {
        return { site_id: this.$route.params.siteID }
      },
    },
    me: {
      query: ME_QUERY,
      result({ data }) {
        if (data != null) {
          this.getUserPermissions();
          this.$permissions.me = data ? data.me : null;
        }
      },
    },
    sites: {
      query: SITE_LIST,
    },
  },
  methods: {
    logout() {
      onLogout(this.$apollo.provider.defaultClient)
      this.$router.push('/login')
    },
    switchSite(selectedOption) {
      this.$router.push({ name: 'SiteDashboard', params: { siteID: selectedOption.id } })
    },
    updateSiteList() {
      this.$apollo.queries.sites.refetch()
    },
    getUserPermissions() {
      this.$apollo.addSmartQuery('userByEmail', {
        query: USER_QUERY,
        variables() {
          return {email: this.me.email}
        },
        result({data}) {
          const permissions = data.userByEmail.permissions.map(perm => perm.name);
          const roles = data.userByEmail.roles.map(role => role.name);
          
          this.$permissions.acoren_user = {
            permissions: permissions,
            roles: roles,
            id: data.userByEmail.id,
          };

          this.editSite = this.$can('Site', 'create');
          this.viewSite = this.$can('SiteDashboard', 'update');
        }
      });
    }
  },
}
</script>

<style scoped>
#cms-logo img {
  height: 2.2em;
}

#nav-bar.development {
  background-color: #000;
}

#nav-bar.staging {
  background-color: #a50d45;
}

</style>