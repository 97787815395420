<template>
  <div class="field" :class="{ 'field-inline': layout == 'inline' }">
    <label :for="`select-${$.uid}`">{{ label }}</label>
    <div 
      class="relative answer-container border px-1 py-2" 
      :class="{'open-menu' : openMenu, 'disabled' : disabled}">
      <span v-for="val in localVal" :key="val.id" >
        <button
          class="opt-container text-white rounded-sm relative mx-1 bg-gray-500"
          @click.prevent="removeAnswer(val.id)">
          {{ val.name }}
          <!-- Note: Fontawesome library is dated and don't want to spend the time updating it. Used the SVG instead for the X. -->
          <svg class="xmark" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
        </button>
      </span>
      <a class="dir-link cursor-pointer" @click.prevent="openMenu = !openMenu" v-if="!disabled" >
        <fa-icon icon="angle-up" :class="{ hidden: !openMenu }" class="dir-icon" />
        <fa-icon icon="angle-down" :class="{ hidden: openMenu }" class="dir-icon" />
      </a>
      <div class="extended-menu absolute left-0 right-0 bg-white border border-t-0">
        <a 
          class="block btn border-0 cursor-pointer font-normal p-0 m-0" 
          v-for="(opt, i) in options" 
          :key="i"
          @click.prevent="toggleAnswer(opt, i)">
          <div
            class="px-3 py-2"
            :class="{'is-active': localVal.some(val => val.id == i)}">
            {{ opt }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import { reactive } from 'vue';

/*
  field (currently selected) example: 
  [
    {id: id, name: 'test'}
  ]

  options example: 
  {id: opt, id: 123}
*/
export default {
  name: 'RWMultipleSelect',
  props: {
    modelValue: (String | Number),
    label: String,
    options: Object,
    includeNull: Boolean,
    layout: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openMenu: false,
      localVal: reactive(this.modelValue) || null,
    }
  },
  created() {
    window.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)){
        this.openMenu = false;
      }
    })
  },
  methods: {
    handleChange() {
      this.$emit('update:modelValue', this.localVal)
    },
    removeAnswer(id) {
      if (this.disabled) return;

      this.localVal = this.localVal.filter(ele => ele.id != id);
      this.handleChange();
    },
    toggleAnswer(opt, id) {
      const answerCheck = this.localVal.some(val => val.id == id);
      if (answerCheck) { 
        this.removeAnswer(id);
      } else {
        this.localVal.push({id: id, name: opt});
        this.handleChange();
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.extended-menu {
  top: 100%;
  display: none;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
}

.open-menu .extended-menu {
  display: block;
}

.opt-container {
  padding: 0.25em 1.25em 0.25em 0.5em;
  margin-bottom: 2px;
}

.opt-container div:hover {
  background-color: #6b7280;
  cursor: pointer;
}

.extended-menu .btn div.is-active {
  background-color: #4b5563;
  color: #fff;
  margin-bottom: 1px;
}

.extended-menu .btn div.is-active:hover {
  background-color: transparent;
  transition: all 0.25s;
}

.xmark {
 position: absolute;
 width: 10px;
 top: 0;
 bottom: 0; 
 margin: auto;
 right: 5px;
 fill: white;
}

.dir-link {
  position: absolute;
  padding: 1em;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-items: center;
}

.answer-container {
  padding-right: 1.75em;
  min-height: 2.5em;
}

.answer-container .btn:hover {
  border-radius: 0;
}

.answer-container.disabled {
  background-color: #edf2f7; 
}

.disabled .xmark {
  display: none;
  }
</style>
